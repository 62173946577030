













































import { defineComponent, inject } from '@vue/composition-api';
import DataConsumptionPollTable from '@/features/app-customer/components/data-consumption-poll-panel/DataConsumptionPollTable.vue';
import { mapActions } from 'vuex';
import { EMPTY, Observable, firstValueFrom } from 'rxjs';
import { CONSUMPTION_API_REST_CLIENT } from '@/features/core/container';
import { RestClient } from '@/features/core/rest-client/rest-client';
import usersQuery from './users.gql';
import customersQuery from './customers.gql';
import { Customers_customers_items } from '@/features/app-customer/components/data-consumption-poll-panel/__generated__/Customers';
import { Users_users_items } from '@/features/app-customer/components/data-consumption-poll-panel/__generated__/Users';
import Spinner from '@/components/clickables/Spinner.vue';

// Enum type for auditType
enum AuditTypeEnum {
  GET_CONSUMPTION_DATA = 'GET_CONSUMPTION_DATA',
  LIST_THIRD_PARTIES = 'LIST_THIRD_PARTIES',
  ENABLE_CONSUMPTION_DATA_ACCESS = 'ENABLE_CONSUMPTION_DATA_ACCESS',
  REVOKE_CONSUMPTION_DATA_ACCESS = 'REVOKE_CONSUMPTION_DATA_ACCESS',
  LIST_CONSUMPTION_DATA_ACCESS = 'LIST_CONSUMPTION_DATA_ACCESS',
  UPDATE_CONSUMPTION_DATA_ACCESS = 'UPDATE_CONSUMPTION_DATA_ACCESS',
  DELETE_CONSUMPTION_DATA_ACCESS = 'DELETE_CONSUMPTION_DATA_ACCESS',
}

// Normal type for the object
export type AuditObject = {
  id: string;
  userId: string;
  customerId: string;
  thirdPartyApiId: string;
  auditType: AuditTypeEnum;
  timestamp: string;
};

export type AuditReturn = {
  data: AuditObject[];
  pagination: {
    offset: number;
    limit: number;
    count: number;
    total: number;
  };
};

type ThirdPartyData = {
  Id: string;
  Name: string;
  UserId: string;
  VendorApiType: string;
  TokenGenerationUrl: string;
  AccessUpdateUrl: string;
};

const auditTypesObject: any = {
  GET_CONSUMPTION_DATA: 'Get Consumption Data',
  LIST_THIRD_PARTIES: 'List Third Parties',
  ENABLE_CONSUMPTION_DATA_ACCESS: 'Enable Consumption Data Access',
  REVOKE_CONSUMPTION_DATA_ACCESS: 'Revoke Consumption Data Access',
  LIST_CONSUMPTION_DATA_ACCESS: 'List Consumption Data Access',
  UPDATE_CONSUMPTION_DATA_ACCESS: 'Update Consumption Data Access',
  DELETE_CONSUMPTION_DATA_ACCESS: 'Delete Consumption Data Access',
};

interface DataConsumptionPollPanelProps {
  customerId: string;
}

export default defineComponent<DataConsumptionPollPanelProps, Record<string, unknown>, any, any>({
  components: { DataConsumptionPollTable, Spinner },
  props: {
    customerId: {
      required: false,
    },
  },
  setup() {
    // Inject the RestClient instance
    const client = inject<RestClient>(CONSUMPTION_API_REST_CLIENT);
    return {
      client, // Expose the client to the component's template or other logic if needed
    };
  },
  data() {
    return {
      data: 0,
      loading: true,
      refreshing: false,
      thirdParties: [],
      auditData: [],
      users: [],
      customers: [],
      // Filtering
      filterAuditType: undefined,
      selectedUserId: undefined,
      pageSize: 10,
      totalPages: 0,
    };
  },
  computed: {
    filteredAuditValues() {
      const filteredAuditData = this.auditData;

      return filteredAuditData;
    },
    userOptions() {
      return this.users.map((user: Users_users_items) => {
        return {
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        };
      });
    },
    auditOptions() {
      const auditOptions = [];
      for (const auditTypesObjectKey in auditTypesObject) {
        auditOptions.push({
          label: auditTypesObject[auditTypesObjectKey],
          value: auditTypesObjectKey,
        });
      }
      return auditOptions;
    },
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    loadThirdParties(): Observable<ThirdPartyData[]> {
      return this.client?.get(`/api/v1/consumption/third-parties`) ?? EMPTY;
    },
    getAuditData(
      offset: number,
      limit: number,
      userId: string | undefined,
      auditType: string | undefined,
    ): Observable<AuditReturn> {
      const userIdsFilter = userId ? `&userIds=${userId}` : '';
      const auditTypesFilter = auditType ? `&auditTypes=${auditType}` : '';
      return (
        this.client?.get(
          `/api/v1/consumption/request-audits?offset=${offset}&limit=${limit}${userIdsFilter}${auditTypesFilter}`,
        ) ?? EMPTY
      );
    },
    async refreshAuditData(pageNumber: number): Promise<void> {
      this.refreshing = true;
      await this.loadAuditData(pageNumber);
      this.processData();
      this.refreshing = false;
    },
    processData() {
      // First check if this is for the customer tab
      if (this.customerId) {
        this.auditData = this.auditData.filter((data: AuditObject) => {
          return data.customerId === this.customerId;
        });
      }
      this.auditData = this.auditData.map((audit: AuditObject) => {
        const thirdParty = this.thirdParties.find((party: ThirdPartyData) => party.Id === audit.thirdPartyApiId);
        const user = this.users.find((user: Users_users_items) => user.id === audit.userId);
        const type = auditTypesObject[audit.auditType];
        const customer = this.customers.find((customer: Customers_customers_items) => customer.id === audit.customerId);
        // Determine customer column text
        let customerText = customer ? customer.name : `No customer found`;
        if (!customer && user?.admin) {
          // Admin user is not linked to a specific customer
          customerText = 'Administrator';
        }

        return {
          ...audit,
          customer: customerText,
          thirdPartyApi: thirdParty ? thirdParty.Name : `No Third Party Found`,
          userName: user ? `${user.firstName} ${user.lastName}` : `No User Found`,
          displayType: type,
          timestamp: new Date(audit.timestamp).toLocaleString(),
        };
      });
    },
    async loadCustomers(): Promise<void> {
      const { data } = await this.$apollo.query({
        query: customersQuery,
        fetchPolicy: 'network-only',
      });
      if (data?.customers?.items) {
        this.customers = data.customers.items;
      }
    },
    async loadUsers(userIds: string[]): Promise<void> {
      const { data } = await this.$apollo.query({
        query: usersQuery,
        fetchPolicy: 'network-only',
        variables: {
          userIds,
        },
      });
      if (data?.users?.items) {
        this.users = data.users.items;
      }
    },
    async loadData(): Promise<void> {
      this.loading = true;
      this.loadThirdParties().subscribe((value: ThirdPartyData[]) => {
        this.thirdParties = value;
      });
      const firstPageNumber = 1;
      await this.loadAuditData(firstPageNumber);
      await this.loadCustomers();
      this.processData();
      this.loading = false;
    },
    async loadAuditData(pageNumber: number): Promise<void> {
      const offset = (pageNumber - 1) * this.pageSize;
      const auditResponse = await firstValueFrom<AuditReturn>(
        this.getAuditData(offset, this.pageSize, this.selectedUserId, this.filterAuditType),
      );
      const data: AuditObject[] = auditResponse.data;
      const pagination = auditResponse.pagination;
      const userIds = new Set<string>(data.map((audit) => audit.userId));
      await this.loadUsers(Array.from(userIds));

      this.auditData = data;
      this.totalPages = Math.floor(pagination.total / this.pageSize);
    },
    async changeDataValue(property: string, value: string | null): Promise<void> {
      this[property] = value === null ? undefined : value;

      const firstPageNumber = 1;
      await this.refreshAuditData(firstPageNumber);
    },
  },
  async created() {
    await this.loadData();
  },
});
