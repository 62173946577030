var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',[_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',{staticClass:"info-audit-message"},[_vm._v(" Hierbei handelt es sich um Protokolle, die die Aktionen von Clients zeigen, die mit der Verbrauchs-API interagieren ")]),_c('ui-flex',{attrs:{"items":"center"}},[_c('ui-cell',{attrs:{"min":150,"max":280}},[_c('ui-input-select',{attrs:{"data-element-id":'audit-type-select',"value":_vm.filterAuditType,"options":_vm.auditOptions,"placeholder":"Prüfungstyp","reduce":function (ref) {
	var value = ref.value;

	return value;
}},on:{"update":function($event){return _vm.changeDataValue('filterAuditType', $event)}}})],1),_c('ui-cell',{attrs:{"min":150,"max":280}},[_c('ui-input-select',{attrs:{"data-element-id":'user-type-select',"value":_vm.selectedUserId,"options":_vm.userOptions,"placeholder":"Benutzer","reduce":function (ref) {
	var value = ref.value;

	return value;
}},on:{"update":function($event){return _vm.changeDataValue('selectedUserId', $event)}}})],1)],1)],1)],1),_c('ui-table-skeleton',{attrs:{"ready":!_vm.loading,"column-count":4,"line-count":2,"header-line-count":2}}),(_vm.refreshing)?_c('Spinner',{attrs:{"dark":true}}):_vm._e(),(!_vm.loading)?_c('data-consumption-poll-table',{attrs:{"audit-data":_vm.filteredAuditValues,"customer-id":_vm.customerId,"page-size":_vm.pageSize,"total-pages":_vm.totalPages},on:{"current-page-update":function($event){return _vm.refreshAuditData($event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }