import { isArray, isObject, mapValues, omitBy } from 'lodash';

export function omitDeepBy(value: unknown, iteratee: (value: unknown, key: keyof never) => boolean): unknown {
  const cb = (v: unknown): unknown => omitDeepBy(v, iteratee);

  return isObject(value)
    ? isArray(value)
      ? value.map(cb)
      : mapValues(omitBy(value as Record<string, unknown>, iteratee), cb)
    : value;
}
