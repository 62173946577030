
















import { defineComponent } from '@vue/composition-api';
import { Order } from '@/types/iot-portal';
import { AuditObject } from '@/features/app-customer/components/data-consumption-poll-panel/DataConsumptionPollPanel.vue';
import { Column } from '@/features/ui/table/model';
import { Emit } from 'vue-property-decorator';

interface DataConsumptionPollTableProps {
  auditData: AuditObject[];
  customerId: {
    required: boolean;
  };
  pageSize: {
    required: boolean;
  };
  totalPages: {
    required: boolean;
  };
}

export default defineComponent<DataConsumptionPollTableProps, Record<string, unknown>, any, any>({
  props: {
    auditData: Array,
    customerId: String,
    pageSize: Number,
    totalPages: Number,
  },
  data() {
    return {
      columns: [
        { name: 'displayType', label: 'Audit Type', width: 150 },
        { accessor: 'userName', name: 'userName', label: 'User', width: 150 },
        { accessor: 'thirdPartyApi', name: 'thirdPartyApi', label: 'Third Party', width: 200 },
        { name: 'timestamp', label: 'Timestamp', width: 200 },
      ] as Column[],
      sortKeys: [
        {
          key: 'name',
          direction: Order.ASC,
        },
      ],
      loading: false,
      // pagination
      currentPage: 1,
      displayedAuditData: [],
    };
  },
  watch: {
    currentPage(newPage) {
      this.displayedAuditData = this.auditData;

      this.$emit('current-page-update', newPage);
    },
    auditData() {
      this.handleNewAuditData();
    },
  },
  created() {
    this.handleNewAuditData();
  },
  methods: {
    handleNewAuditData() {
      // First check if we haven't added the customer already
      const customerColumn = this.columns.find((column: Column) => column.name === 'customer');
      // Add customer column if in "customer mode"
      if (!customerColumn && !this.customerId) {
        this.columns.unshift({ name: 'customer', label: 'Customer', width: 150 });
      }
      // Get the first <pageSize> items from this.auditData
      this.displayedAuditData = this.auditData;
    },
  },
});
